import styled from 'styled-components'

export const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex !important;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;

  opacity: 0.2;
  background-color: #000;
`

export const Container = styled.div`
  margin: auto auto;

  background-color: #000;
  opacity: 0.8;
`
