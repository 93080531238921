import axios from 'axios'
import { getToken } from '../Reducers/storageApp'

let token = getToken()
const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API,
})

api.interceptors.request.use(
  (config) => {
    const access_token = getToken()
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`
      return config
    } else return Promise.reject()
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default api
