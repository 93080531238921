import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    setstate(Mask(value))
  }, [value])

  const onInput = (ev) => {
    var valMasked = Mask(ev.target.value)
    setstate(valMasked)
    if (onChange) {
      onChange({ target: { value: valMasked.replace(/\D/g, ''), ...inputProps } })
    }
  }

  const Mask = (value) => {
    if (value === undefined || value === null) return

    if (value.replace(/\D/g, '').length > 10) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(\d{4})\d+?$/, '$1')
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})\d+?$/, '$1')
    }
  }

  return <Input onChange={onInput} type="text" value={state} {...inputProps} />
}

export default InputComponent
