import React, { useEffect, useState } from 'react'
import { toast, Bounce, i18n, Loading, InputCheck, useDispatch } from '../../../Components'

import { GetTokenApp, LoginUsuario } from '../../../Service/ApiService'
import { ContainerForm, Input, Button, Link, FooterLogin } from '../styles'
import LoadAcessos from '../../../Components/LoadAcessos'
import ModalTermo from './ModalTermo'
import { getUserLogState, putToken, putUser, putUserLogState } from '../../../Reducers/storageApp'

export default function Element() {
  const dispatch = useDispatch()
  const [values, setValues] = useState({ email: '', senha: '', lembrarSenha: false })
  const [isLoading, setIsLoading] = useState(true)
  const [TokenApp, setTokenApp] = useState()
  const [isLoadAcessos, setIsLoadAcessos] = useState(false)
  const [modTermos, setModTermos] = useState({ show: false, respLogin: {} })

  async function onLogin(email = values.email, senha = values.senha) {
    try {
      setIsLoading(true)
      var token = await getTokenApp()
      let stringToCodify = email + ':' + senha
      let encoded = btoa(stringToCodify)

      const response = await LoginUsuario(token, encoded)
      if (!response) {
        toast('Ops, ocorreu um problema na verificação da sua conta', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        setIsLoading(false)
        return undefined
      }

      if (response.result) {
        await putToken(response.token, response.expiration)
        if (values.lembrarSenha) putUserLogState(values.email + ':' + values.senha)
        //if (response.usuario.idioma !== '') localStorage.setItem('i18nextLng', response.usuario.idioma)

        if (response.usuario.showTermos) {
          setModTermos({ show: true, respLogin: response })
        } else onSaveState(response)
      } else toast(response.message, { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    } catch (error) {
      console.error(error)
      toast(`${error}`, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
    setIsLoading(false)
  }

  async function onSubmit(ev) {
    ev.preventDefault()

    if (values.email.length < 6) {
      toast(i18n.t('login.signIn.MSG_ErrorEmailValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (values.senha.length < 6) {
      toast(i18n.t('login.signIn.MSG_ErrorPassValidate'), { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    onLogin()
  }

  async function onSaveState(result) {
    if (result.usuario.isExecutivo)
      await putUser({
        id: result.usuarioId,
        name: result.usuario.nome,
        perfil: result.usuario.perfilId,
        idioma: result.usuario.idioma,
        isExecutivo: result.usuario.isExecutivo,
        contaId: '',
        contaNm: '',
        tpVenda: '',
      })
    else
      await putUser({
        id: result.usuarioId,
        name: result.usuario.nome,
        perfil: result.usuario.perfilId,
        idioma: result.usuario.idioma,
        isExecutivo: result.usuario.isExecutivo,

        contaId: result.usuario.contaId,
        contaNm: result.usuario.contaNm,
        tpVenda: result.usuario.tpVenda,
      })

    setIsLoadAcessos(true)
  }

  async function onLoadAcessos() {
    window.location.href = '/#/admin'
  }

  ////

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  async function getTokenApp() {
    var token = TokenApp
    if (!TokenApp) {
      var response = await GetTokenApp()
      if (response?.token) {
        dispatch({ type: 'stPortal', value: response.stPortal })
        //if (response.stPortal !== 'D') return

        token = response.token
        setTokenApp(response.token)
      } else toast(i18n.t('login.signIn.MSG_ErrorGetTokenApp'), { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
    return token
  }
  async function getLogState() {
    var userLogState = getUserLogState()
    if (userLogState) {
      for (let i = 0; i < userLogState.length - 1; i++) {
        if (userLogState.substring(i, i + 1) === ':') {
          onLogin(userLogState.substring(0, i), userLogState.substring(i + 1, userLogState.length))
        }
      }
    }
    await getTokenApp()
    setIsLoading(false)
  }
  useEffect(() => {
    setTimeout(function () {
      getLogState()
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onTermos() {
    onSaveState(modTermos.respLogin)
    setModTermos({ ...modTermos, show: false })
  }

  return (
    <>
      <Loading isVisible={isLoading} />
      {isLoadAcessos && <LoadAcessos onChange={onLoadAcessos} token={TokenApp} />}
      {modTermos.show && <ModalTermo usuarioId={modTermos.respLogin.usuarioId} change={onTermos} />}

      <ContainerForm onSubmit={onSubmit}>
        {TokenApp && (
          <>
            <Input autoFocus type="email" name="email" placeholder={i18n.t('login.signIn.email')} onChange={onChange} value={values.email} />
            <Input type="password" name="senha" placeholder={i18n.t('login.signIn.password')} onChange={onChange} value={values.senha} />

            <Button type="submit">{i18n.t('login.signIn.buttonSubmit')}</Button>

            <FooterLogin>
              <InputCheck value={values.lembrarSenha} label="Lembrar-me" onChange={onChange} name="lembrarSenha" styleLabel={{ fontSize: '1rem' }} />

              <Link href="/#/Login/Recover">{i18n.t('login.signIn.buttonRecover')}</Link>
            </FooterLogin>
          </>
        )}
      </ContainerForm>
    </>
  )
}
