import React, { Fragment } from 'react'
import { Label, Tooltip } from 'reactstrap'

class TooltipItem extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      tooltipOpen: false,
    }
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    })
  }

  render() {
    return (
      <Fragment>
        <Label style={{ marginLeft: '5px', height: '18px' }} className="badge badge-pill badge-secondary" color="primary" id={'TooltipLight-' + this.props.id}>
          {this.props.text}
        </Label>
        <Tooltip className="tooltip-light" placement={this.props.placement} isOpen={this.state.tooltipOpen} target={'TooltipLight-' + this.props.id} toggle={this.toggle}>
          {this.props.content}
        </Tooltip>
      </Fragment>
    )
  }
}

export default TooltipItem
