import React, { useEffect, useState } from 'react'
import { ReactCSSTransitionGroup, i18n, useDispatch, useSelector } from '../../Components'

import { Route, Routes, useSearchParams } from 'react-router-dom'
import { Wrapper, CardLogo, CardBg, Content } from './styles'

import logo from '../../Assets/Images/logo.png'
import SingIn from './SingIn'
import Recover from './Recover'
import NewPass from './NewPass'

import Maintenance from './Maintenance'

export default function Componente() {
  const dispatch = useDispatch()
  const [params] = useSearchParams()
  const stPortal = useSelector((state) => state.stPortal)
  const [locStPortal, setLocStPortal] = useState()

  useEffect(() => {
    if (params.get('p') === 'm') setLocStPortal('D')
    else setLocStPortal(stPortal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stPortal])

  useEffect(() => {
    dispatch({ type: 'stPortal', value: 'D' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {locStPortal === 'D' ? (
        <Wrapper>
          <CardBg />
          <Content>
            <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
              <CardLogo>
                <img src={logo} alt="Logo_App" />
                <h1>{i18n.t('login.title')}</h1>
              </CardLogo>
              <Routes>
                <Route exact path="/" element={<SingIn />} />
                <Route path="/recover" element={<Recover />} />
                <Route path="/pass/:id" element={<NewPass />} />
              </Routes>
            </ReactCSSTransitionGroup>
          </Content>
        </Wrapper>
      ) : (
        <Maintenance />
      )}
    </>
  )
}
