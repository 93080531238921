import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'

export default function InputComponent({ value, label, onChange, isHighlight = false, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    setstate(value)
  }, [value])

  const onSelect = (ev) => {
    const { checked } = ev.target
    setstate(checked)

    if (onChange) {
      onChange({ target: { value: checked, ...inputProps } })
    }
  }

  return (
    <Container>
      <label className="RadioContainer">
        <SelectBox type="radio" value={state} onClick={onSelect} {...inputProps} />
        <span className="RadioCheckmark"></span>
      </label>
      <Label isHighlight={isHighlight}>{label}</Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const SelectBox = styled(Input)``
const Label = styled.label`
  color: ${(props) => props.isHighlight && 'var(--primary)'};
  font-size: ${(props) => props.isHighlight && '1.5rem'};
  font-weight: ${(props) => props.isHighlight && '600'};
`
