import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  background-color: #fff;
  padding: 0 5%;
`

export const BoxImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  > img {
    width: 50%;
    max-width: 600px;
    margin: auto !important;
  }
  > div {
    margin-left: -10% !important;
  }

  @media (max-width: 688px) {
    flex-direction: column;
    > img {
      width: 80% !important;
      margin: auto !important;
    }
    > div {
      margin-left: -10% !important;
    }
  }
`
export const Title = styled.h3`
  color: #3f51b5;
  font-size: 32px;
  font-weight: 500;
`

export const SubTitle = styled.div`
  padding-top: 10px;
  font-size: 21px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 688px) {
    font-size: 18px;
  }
`
